/* Common imports */

import React from "react";

/* mui imports */

import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Divider,
	Typography,
	Slide,
	Select,
	MenuItem,
	TextField,
	FormControl,
	InputLabel,
} from "@mui/material";
import { styled } from "@mui/system";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import Brightness1Icon from "@mui/icons-material/Brightness1";

/* redux impoerts */
import {
	SearchFilterId,
	SearchFilterOption,
	SearchFilterOptionTextActive,
} from "../../redux-magic/sub-interfaces/search-types";
import StateInterface from "../../redux-magic/state-interface";
import { updateSearchFilterActiveOptionsThunk, updateSearchFilterSelectedOptionsThunk } from "../../redux-magic/store";
import { updateSearchFilterAppliedThunk } from "../../redux-magic/thunks";
import search from "../../pages/search";

/* stylings */

const CustomAccordion = styled(Accordion)(({ theme }) => ({
	borderRadius: "16px",
	boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
	"&:: before": {
		display: "none",
	},
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
	padding: "var(--Spacing-spacing-sm, 8px) var(--Spacing-spacing-md, 16px)",
	margin: "0rem",
}));

const CustomArrowIcon = styled(KeyboardArrowDownOutlinedIcon)(({ theme }) => ({
	width: "1.5rem",
	height: "1.5rem",
	borderRadius: "4px",
	color: theme.palette.mode == "dark" ? "#FFFFFF" : "#623816",
	backgroundColor: "#FC801929",
}));

const CustomAspectRatioIcon = styled(AspectRatioIcon)(({ theme }) => ({
	marginRight: "1rem",
	color: theme.palette.primary.main,
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	flexWrap: "wrap",
	padding: "1rem",
	margin: "0rem",
	gap: "var(--Spacing-spacing-md, 16px)",
}));

const CustomSelect = styled(Select)(({ theme }) => ({
	width: "100%",
	borderRadius: "0.5rem",
	color: theme.palette.primary.main,
}));

const Text = styled(TextField)(({ theme }) => ({
	"& .MuiOutlinedInput-root": {
		borderRadius: "0.5rem",
		fontSize: "1rem",
		fontWeight: "400",
		lineHeight: "1.125rem",
		width: "6.813rem",
		height: "2.125rem",
		color: "#FFFFFF",
	},
}));
const SubText = styled(Typography)(({ theme }) => ({
	fontSize: "0.813rem",
	fontWeight: "400",
	lineHeight: "1.125rem",
	padding: "3px 6px",
}));

export const _m_plot_dimensions = ({
	search_id,
	dispatch,
	plot_dimensions,
}: {
	search_id: SearchFilterId;
	plot_dimensions: StateInterface["search_filters_state"]["plot_dimensions"];
	dispatch: Function;
}) => {
	const [slide, setSlide] = React.useState<boolean>(false);

	const handleUpdatePlotDimention = (selectedOptions: Array<SearchFilterOptionTextActive>) => {
		const updatedActiveOptions = plot_dimensions.active_options.map((item: SearchFilterOptionTextActive) => ({
			...item,
			is_selected: item.id === selectedOptions[0].id,
		}));

		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions: selectedOptions,
			}),
		);

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: search_id,
				newOptions: updatedActiveOptions,
			}),
		);
	};

	React.useEffect(() => {
		// lets check if the plot dimentions is selected or not
		let isPlotDimentionSelected = plot_dimensions
			? plot_dimensions.selected_options.length !== 0
				? true
				: false
			: false;

		// lets set the isApplied value to true if the plot dimentions is selected
		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: search_id, isApplied: isPlotDimentionSelected }));
	}, [plot_dimensions, search_id, dispatch]);

	return (
		<CustomAccordion
			square={true}
			disableGutters={true}
			onChange={() => {
				setSlide(!slide);
			}}
		>
			<CustomAccordionSummary
				expandIcon={<CustomArrowIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header"
			>
				<CustomAspectRatioIcon /> Plot Dimention{" "}
				{plot_dimensions.selected_options.length != 0 && (
					<Brightness1Icon sx={{ color: "primary.main", width: "8px", marginTop: "-0.25rem", marginLeft: "0.25rem" }} />
				)}
			</CustomAccordionSummary>
			<Divider
				sx={{
					borderBottom: "1px solid var(--Other-New-Divider, rgba(255, 255, 255, 0.12))",
				}}
			/>
			<Slide
				direction="up"
				in={slide}
				mountOnEnter
				unmountOnExit
			>
				<CustomAccordionDetails>
					<FormControl sx={{ width: "100%" }}>
						<InputLabel id="plot-dimention-lable">Plot Dimention</InputLabel>
						<CustomSelect
							labelId="plot-dimention-lable"
							id="plot-dimention-select"
							value={((plot_dimensions?.selected_options[0] as SearchFilterOption)?.value as string) || ""}
							label="Plot Dimention"
							onChange={(e) => {
								const selectedValue = e.target.value as string;
								const selectedOptions = plot_dimensions.possible_options.find(
									(option) => option.value === selectedValue,
								);
								handleUpdatePlotDimention([selectedOptions] as Array<SearchFilterOptionTextActive>);
							}}
						>
							{plot_dimensions.possible_options.map((item: SearchFilterOptionTextActive, index: number) => (
								<MenuItem
									key={item.id}
									value={item.value}
								>
									{item.title}
								</MenuItem>
							))}
						</CustomSelect>
					</FormControl>
				</CustomAccordionDetails>
			</Slide>
		</CustomAccordion>
	);
};
