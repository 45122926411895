/* Common imports */
import React, { use } from "react";

/* mui imports */
import {
	useTheme,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Divider,
	Typography,
	Slide,
	Button,
} from "@mui/material";
import { styled } from "@mui/system";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Brightness1Icon from "@mui/icons-material/Brightness1";

/* redux imports */
import {
	SearchFilter,
	SearchFilterId,
	SearchFilterOption,
	SearchFilterOptionText,
	SearchFilterOptionTextActive,
} from "../../redux-magic/sub-interfaces/search-types";
import StateInterface from "../../redux-magic/state-interface";
import {
	updateSearchFilterActiveOptionsThunk,
	updateSearchFilterAppliedThunk,
	updateSearchFilterSelectedOptionsThunk,
	updateSearchFilterVisibilityThunk,
	updateSearchFilterIsAccordionOpenThunk,
} from "../../redux-magic/thunks";

import {
	construction_options_active,
	interiors_and_decor_options_active,
	daily_home_care_and_security_options_active,
	cleaning_and_maintenance_options_active,
	home_utilities_options_active,
	home_and_appliance_repair_options_active,
	legal_and_finance_options_active,
	liaisoning_options_active,
	transport_options_active,
	grooming_and_other_personal_care_options_active,
	social_service_options_active,
	food_options_active,
	hospitality_options_active,
	other_options_active,
} from "../../lib/data/search-filters/_x_service_subtype";

import { rest } from "lodash";

/* stylings */
const CustomAccordion = styled(Accordion)(({ theme }) => ({
	borderRadius: "16px",
	boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
	"&:: before": {
		display: "none",
	},
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
	padding: "var(--Spacing-spacing-sm, 8px) var(--Spacing-spacing-md, 16px)",
	margin: "0rem",
}));

const CustomArrowIcon = styled(KeyboardArrowDownOutlinedIcon)(({ theme }) => ({
	width: "1.5rem",
	height: "1.5rem",
	borderRadius: "4px",
	color: theme.palette.mode == "dark" ? "#FFFFFF" : "#623816",
	backgroundColor: "#FC801929",
}));

const CustomHomeWorkOutlinedIcon = styled(HomeWorkOutlinedIcon)(({ theme }) => ({
	marginRight: "1rem",
	color: theme.palette.primary.main,
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	flexWrap: "wrap",
	padding: "1rem",
	margin: "0rem",
	gap: "var(--Spacing-spacing-md, 16px)",
}));

const CheckBoxDiv = styled(Button)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "0.5rem",
	padding: "var(--Spacing-spacing-xs, 4px)",
	// boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
	gap: "var(--Spacing-spacing-xs, 4px)",
	cursor: "pointer",
}));

const Text = styled(Typography)(({ theme }) => ({
	fontSize: "1rem",
	fontWeight: "400",
	lineHeight: "1.125rem",
	padding: "3px 6px",
	textTransform: "capitalize",
	color: theme.palette.mode == "dark" ? "#FFFFFF" : "#623816",
}));

const DoneIcon = styled(DoneOutlinedIcon)(({ theme }) => ({
	color: "#623816",
	height: "1rem",
	width: "1rem",
}));

const AddIcon = styled(AddOutlinedIcon)(({ theme }) => ({
	color: theme.palette.mode == "dark" ? "#FFFFFF" : "#623816",
	height: "1rem",
	width: "1rem",
}));

type VisibilityConfig = {
	[key: string]:
		| {
				[key: string]: string[];
		  }
		| string[];
};

export const _w_service_type = ({
	search_id,
	service_category_value,
	service_type,
	dispatch,
}: {
	search_id: SearchFilterId;
	service_category_value: string;
	service_type: StateInterface["search_filters_state"]["service_type"];
	dispatch: Function;
}) => {
	const theme = useTheme();
	const [slide, setSlide] = React.useState<boolean>(service_type?.is_accordion_open);

	const handleUpdateServiceType = (value: Array<SearchFilterOptionTextActive>) => {
		const selectedOptionId = value[0]?.id;

		let serviceTypeAffects = service_type?.affects[0];

		let selected_options: Array<SearchFilterOption> = value.map((option) => {
			let { is_selected, ...rest } = option;
			return rest;
		});

		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions: selected_options,
			}),
		);

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: search_id,
				newOptions: service_type.active_options.map((item) => ({
					...item,
					is_selected: item.id === value[0]?.id ? true : false,
				})),
			}),
		);

		dispatch(updateSearchFilterIsAccordionOpenThunk({ searchFilterId: serviceTypeAffects, isAccordionOpen: true }));

		const serviceSubTypeOptions =
			selectedOptionId === "construction"
				? construction_options_active
				: selectedOptionId === "interiors_and_decor"
					? interiors_and_decor_options_active
					: selectedOptionId === "daily_home_care_and_security"
						? daily_home_care_and_security_options_active
						: selectedOptionId === "cleaning_and_maintenance"
							? cleaning_and_maintenance_options_active
							: selectedOptionId === "home_utilities"
								? home_utilities_options_active
								: selectedOptionId === "home_and_appliance_repair"
									? home_and_appliance_repair_options_active
									: selectedOptionId === "legal_and_finance"
										? legal_and_finance_options_active
										: selectedOptionId === "liaisoning"
											? liaisoning_options_active
											: selectedOptionId === "transport"
												? transport_options_active
												: selectedOptionId === "grooming_and_other_personal_care"
													? grooming_and_other_personal_care_options_active
													: selectedOptionId === "social_service"
														? social_service_options_active
														: selectedOptionId === "food"
															? food_options_active
															: selectedOptionId === "hospitality"
																? hospitality_options_active
																: selectedOptionId === "other"
																	? other_options_active
																	: construction_options_active;

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: service_type ? (service_type.affects[0] as SearchFilterId) : ("" as SearchFilterId),
				newOptions: serviceSubTypeOptions,
			}),
		);
	};

	React.useEffect(() => {
		// lets check if the service_type is selected
		let isServiceTypeSelected = service_type ? (service_type.selected_options.length !== 0 ? true : false) : false;

		// lets update the the applied filter to true is data exists
		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: search_id, isApplied: isServiceTypeSelected }));

		// lets check if the service_type affects any other filter
		let serviceTypeAffects = service_type ? (service_type.affects[0] as SearchFilterId) : ("" as SearchFilterId);

		// lets update the visibility of the affected filter
		dispatch(
			updateSearchFilterVisibilityThunk({ searchFilterId: serviceTypeAffects, isVisible: isServiceTypeSelected }),
		);
	}, [service_type, dispatch, search_id]);

	return (
		<CustomAccordion
			square={true}
			disableGutters={true}
			defaultExpanded={service_type?.is_accordion_open as boolean}
			onChange={() => {
				setSlide(!slide);
			}}
		>
			<CustomAccordionSummary
				expandIcon={<CustomArrowIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header"
			>
				<CustomHomeWorkOutlinedIcon /> Service Type{" "}
				{service_type?.selected_options.length != 0 && (
					<Brightness1Icon sx={{ color: "primary.main", width: "8px", marginTop: "-0.25rem", marginLeft: "0.25rem" }} />
				)}
			</CustomAccordionSummary>
			<Divider
				sx={{
					borderBottom: "1px solid var(--Other-New-Divider, rgba(255, 255, 255, 0.12))",
				}}
			/>
			<Slide
				direction="up"
				in={slide}
				mountOnEnter
				unmountOnExit
			>
				<CustomAccordionDetails>
					{service_type?.active_options.map((item: SearchFilterOptionTextActive) => (
						<CheckBoxDiv
							key={item.id}
							onClick={() => {
								item.is_selected
									? handleUpdateServiceType([] as Array<SearchFilterOptionTextActive>)
									: handleUpdateServiceType([item] as Array<SearchFilterOptionTextActive>);
							}}
							sx={{
								background: item.is_selected ? "#FC801933" : "",
								border: item.is_selected
									? "1px solid var(--Other-New-Outline-Border, rgba(255, 255, 255, 0.23))"
									: "1px solid #C0C0C0",
							}}
						>
							{item.is_selected ? (
								<DoneIcon sx={{ color: theme.palette.mode === "dark" ? "#ffffff" : "#623816" }} />
							) : (
								<AddIcon />
							)}
							<Text variant="body1">{item.title}</Text>
						</CheckBoxDiv>
					))}
				</CustomAccordionDetails>
			</Slide>
		</CustomAccordion>
	);
};
