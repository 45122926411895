/* Common imports */

import React from "react";
import { styled } from "@mui/system";
/* mui imports */
import { Chip, useTheme, useMediaQuery } from "@mui/material";
import { SearchFilterId, SearchFilterOption } from "../../../../redux-magic/sub-interfaces/search-types";
import StateInterface from "../../../../redux-magic/state-interface";
import {
	updateSearchFilterActiveOptionsThunk,
	updateSearchFilterSelectedOptionsThunk,
	updateSearchFilterVisibilityThunk,
} from "../../../../redux-magic/thunks";
import {
	construction_options_active,
	interiors_and_decor_options_active,
	daily_home_care_and_security_options_active,
	cleaning_and_maintenance_options_active,
	home_utilities_options_active,
	home_and_appliance_repair_options_active,
	legal_and_finance_options_active,
	liaisoning_options_active,
	transport_options_active,
	grooming_and_other_personal_care_options_active,
	social_service_options_active,
	food_options_active,
	hospitality_options_active,
	other_options_active,
} from "../../../../lib/data/search-filters/_x_service_subtype";
import { updateSearchFilterAppliedThunk } from "../../../../redux-magic/thunks";

/* redux imports */

/* stylings */
const ServiceChips = styled(Chip)(({ theme }) => ({
	display: "flex",
	alignSelf: "stretch",
	borderRadius: "8px",
	// gap: "0.5rem",
})) as typeof Chip;

const ServiceCategoryComponent = ({
	search_id,
	search_filter_state,
	dispatch,
}: {
	search_id: SearchFilterId;
	search_filter_state: StateInterface["search_filters_state"]["service_type"];
	dispatch: Function;
}) => {
	const theme = useTheme();
	const handleClickServiceChips = (value: Array<SearchFilterOption>) => {
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions: value,
			}),
		);

		/* 
		
		* Update Service Subtype Active Options based on the selected Service type
		
		*/

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: "service_subtype",
				newOptions:
					value[0]?.id === "construction"
						? construction_options_active
						: value[0]?.id === "interiors_and_decor"
							? interiors_and_decor_options_active
							: value[0]?.id === "daily_home_care_and_security"
								? daily_home_care_and_security_options_active
								: value[0]?.id === "cleaning_and_maintenance"
									? cleaning_and_maintenance_options_active
									: value[0]?.id === "home_utilities"
										? home_utilities_options_active
										: value[0]?.id === "home_and_appliance_repair"
											? home_and_appliance_repair_options_active
											: value[0]?.id === "legal_and_finance"
												? legal_and_finance_options_active
												: value[0]?.id === "liaisoning"
													? liaisoning_options_active
													: value[0]?.id === "transport"
														? transport_options_active
														: value[0]?.id === "grooming_and_other_personal_care"
															? grooming_and_other_personal_care_options_active
															: value[0]?.id === "social_service"
																? social_service_options_active
																: value[0]?.id === "food"
																	? food_options_active
																	: value[0]?.id === "hospitality"
																		? hospitality_options_active
																		: value[0]?.id === "other"
																			? other_options_active
																			: construction_options_active,
			}),
		);

		/*
		
		* change is_selected to true for the selected option 
		
		*/
		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: search_id,
				newOptions: search_filter_state.active_options.map((item) => ({
					...item,
					is_selected: item.id === value[0]?.id ? true : false,
				})),
			}),
		);
	};
	const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
	React.useEffect(() => {
		if (isDesktop) {
			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: search_id,
					isApplied: search_filter_state.selected_options.length != 0 ? true : false,
				}),
			);
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: search_id, isVisible: true }));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search_filter_state, search_id, dispatch]);
	return (
		<div
			style={{
				display: "flex",
				flexFlow: "row wrap",
				alignItems: "center",
				gap: "1rem",
				marginBottom: "1rem",
			}}
		>
			{search_filter_state.possible_options?.map((item: SearchFilterOption) => (
				<ServiceChips
					key={item?.id}
					onClick={() => {
						if (search_filter_state?.selected_options[0]?.id !== item.id) {
							handleClickServiceChips([item] as Array<SearchFilterOption>);
						}
					}}
					label={item?.title}
					clickable
					color="default"
					variant="outlined"
					sx={{
						fontSize: "1rem",
						backgroundColor: search_filter_state?.selected_options[0]?.id === item.id ? "#FC801929" : "transparent",
						color: theme.palette.mode === "dark" ? "rgba(255, 255, 255, 1)" : "black",
						border:
							theme.palette.mode === "dark"
								? search_filter_state?.selected_options[0]?.id === item.id
									? "1px solid transparent"
									: "1px solid rgba(255, 255, 255, 0.23)"
								: search_filter_state?.selected_options[0]?.id === item.id
									? "1px solid transparent"
									: "1px solid rgba(0, 0, 0, 0.12)",
						"&.MuiChip-clickable:hover": {
							backgroundColor: search_filter_state?.selected_options[0]?.id === item.id ? "#FC801929" : "transparent",
						},
					}}
				/>
			))}
		</div>
	);
};
export default ServiceCategoryComponent;
