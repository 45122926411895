/* Common imports */

import React from "react";

/* mui imports */

import {
	useTheme,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Divider,
	Typography,
	Slide,
	Button,
} from "@mui/material";
import { styled } from "@mui/system";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import Brightness1Icon from "@mui/icons-material/Brightness1";

/* mui date picker imports */

import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

/* redux imports */
import { SearchFilterId } from "../../redux-magic/sub-interfaces/search-types";
import StateInterface from "../../redux-magic/state-interface";
import { updateSearchFilterActiveOptionsThunk, updateSearchFilterSelectedOptionsThunk } from "../../redux-magic/store";
import { updateSearchFilterAppliedThunk } from "../../redux-magic/thunks";

/* stylings */

const CustomAccordion = styled(Accordion)(({ theme }) => ({
	borderRadius: "16px",
	boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
	"&:: before": {
		display: "none",
	},
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
	padding: "var(--Spacing-spacing-sm, 8px) var(--Spacing-spacing-md, 16px)",
	margin: "0rem",
}));

const CustomDateRangeOutlinedIcon = styled(DateRangeOutlinedIcon)(({ theme }) => ({
	marginRight: "1rem",
	color: theme.palette.primary.main,
}));

const CustomArrowIcon = styled(KeyboardArrowDownOutlinedIcon)(({ theme }) => ({
	width: "1.5rem",
	height: "1.5rem",
	borderRadius: "4px",
	color: theme.palette.mode == "dark" ? "#FFFFFF" : "#623816",
	backgroundColor: "#FC801929",
}));

const CustomSelectedIcon = styled(Brightness1Icon)(({ theme }) => ({
	color: theme.palette.primary.main,
	width: "8px",
	marginTop: "-0.25rem",
	marginLeft: "0.25rem",
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	flexWrap: "wrap",
	padding: "1rem",
	margin: "0rem",
	gap: "var(--Spacing-spacing-md, 16px)",
}));

const CheckBoxDiv = styled(Button)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "0.5rem",
	padding: "var(--Spacing-spacing-xs, 4px)",
	boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
	gap: "var(--Spacing-spacing-xs, 4px)",
	cursor: "pointer",
}));

const Text = styled(Typography)(({ theme }) => ({
	fontSize: "1rem",
	fontWeight: "400",
	lineHeight: "1.125rem",
	padding: "3px 6px",
	textTransform: "capitalize",
	color: "#FFFFFF",
}));

export const _p_available_from = ({
	search_id,
	available_from,
	dispatch,
}: {
	search_id: SearchFilterId;
	available_from: StateInterface["search_filters_state"]["available_from"];
	dispatch: Function;
}) => {
	const [state, setState] = React.useState<Dayjs | null>(null);
	const [slide, setSlide] = React.useState<boolean>(false);

	const handleUpdateAvailableFrom = (date: dayjs.Dayjs) => {
		const timeStamp = Date.now(); // Converts the Date to a timestamp (number)
		const possession_date = date.toDate().getTime();

		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions: [
					{
						id: "available_from",
						title: "Available From",
						value: {
							min: timeStamp,
							max: possession_date,
							unlock_max: true,
						},
					},
				],
			}),
		);

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: search_id,
				newOptions: [
					{
						id: "available_from",
						title: "Available From",
						value: {
							min: timeStamp,
							max: possession_date,
							unlock_max: true,
						},
					},
				],
			}),
		);
	};

	React.useEffect(() => {
		// lets check if the awailable from date is selected
		let isAwailableFromDateSelected = available_from
			? available_from.selected_options.length !== 0
				? true
				: false
			: false;

		// lets update the search filter applied state
		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: search_id, isApplied: isAwailableFromDateSelected }));
	}, [available_from, search_id, dispatch]);

	return (
		<CustomAccordion
			disableGutters={true}
			square={true}
			onChange={() => {
				setSlide(!slide);
			}}
		>
			<CustomAccordionSummary
				expandIcon={<CustomArrowIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header"
			>
				<CustomDateRangeOutlinedIcon /> Available From{" "}
				{available_from.selected_options.length !== 0 && <CustomSelectedIcon />}
			</CustomAccordionSummary>
			<Divider
				sx={{
					borderBottom: "1px solid var(--Other-New-Divider, rgba(255, 255, 255, 0.12))",
				}}
			/>
			<Slide
				direction="up"
				in={slide}
				mountOnEnter
				unmountOnExit
			>
				<CustomAccordionDetails>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker
							label="Available From*"
							value={dayjs(state)}
							onChange={(date: Dayjs | null) => {
								if (date) {
									setState(date);
									handleUpdateAvailableFrom(date);
								}
							}}
							disablePast
							format="DD/MM/YYYY"
							sx={{ width: "100%" }}
						/>
					</LocalizationProvider>
				</CustomAccordionDetails>
			</Slide>
		</CustomAccordion>
	);
};
