/* Common imports */

import React from "react";

/* mui imports */

import {
	useTheme,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Divider,
	FormControlLabel,
	Typography,
	Slide,
	Button,
} from "@mui/material";
import { styled } from "@mui/system";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import ChairOutlinedIcon from "@mui/icons-material/ChairOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Brightness1Icon from "@mui/icons-material/Brightness1";

/* redux imports */
import StateInterface from "../../redux-magic/state-interface";
import {
	SearchFilterId,
	SearchFilterOption,
	SearchFilterOptionTextActive,
} from "../../redux-magic/sub-interfaces/search-types";

import {
	updateSearchFilterActiveOptionsThunk,
	updateSearchFilterAppliedThunk,
	updateSearchFilterSelectedOptionsThunk,
} from "../../redux-magic/thunks";

/* stylings */

const CustomAccordion = styled(Accordion)(({ theme }) => ({
	borderRadius: "16px",
	boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
	"&:: before": {
		display: "none",
	},
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
	padding: "var(--Spacing-spacing-sm, 8px) var(--Spacing-spacing-md, 16px)",
	margin: "0rem",
}));

const CustomChairOutlinedIcon = styled(ChairOutlinedIcon)(({ theme }) => ({
	marginRight: "1rem",
	color: theme.palette.primary.main,
}));

const CustomArrowIcon = styled(KeyboardArrowDownOutlinedIcon)(({ theme }) => ({
	width: "1.5rem",
	height: "1.5rem",
	borderRadius: "4px",
	color: theme.palette.mode == "dark" ? "#FFFFFF" : "#623816",
	backgroundColor: "#FC801929",
}));

const CustomSelectedIcon = styled(Brightness1Icon)(({ theme }) => ({
	color: theme.palette.primary.main,
	width: "8px",
	marginTop: "-0.25rem",
	marginLeft: "0.25rem",
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	flexWrap: "wrap",
	padding: "1rem",
	margin: "0rem",
	gap: "var(--Spacing-spacing-md, 16px)",
}));

const CheckBoxDiv = styled(Button)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "0.5rem",
	padding: "var(--Spacing-spacing-xs, 4px)",
	// boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
	gap: "var(--Spacing-spacing-xs, 4px)",
	cursor: "pointer",
}));

const Text = styled(Typography)(({ theme }) => ({
	fontSize: "1rem",
	fontWeight: "400",
	lineHeight: "1.125rem",
	padding: "3px 6px",
	textTransform: "capitalize",
	color: theme.palette.mode == "dark" ? "#FFFFFF" : "#623816",
}));

const DoneIcon = styled(DoneOutlinedIcon)(({ theme }) => ({
	color: "#623816",
	height: "1rem",
	width: "1rem",
}));

const AddIcon = styled(AddOutlinedIcon)(({ theme }) => ({
	color: theme.palette.mode == "dark" ? "#FFFFFF" : "#623816",
	height: "1rem",
	width: "1rem",
}));

export const _t_furnishing = ({
	search_id,
	furnishing,
	dispatch,
}: {
	search_id: SearchFilterId;
	furnishing: StateInterface["search_filters_state"]["furnishing"];
	dispatch: Function;
}) => {
	const [slide, setSlide] = React.useState<boolean>(false);

	/* lets create a function to remove the data from the array */

	const handleRemoveData = (item: SearchFilterOption) => {
		// now lets remove the whole object from the array

		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions: furnishing.selected_options.filter((i) => i.value !== item.value),
			}),
		);

		// now lets set the is_selected value in active_options to false if the user removes the item

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: search_id,
				newOptions: furnishing.active_options.map((data: SearchFilterOptionTextActive) => ({
					...data,
					is_selected: item === data ? false : data.is_selected,
				})),
			}),
		);
	};

	const handleUpdateFurnishing = (newValue: Array<SearchFilterOption>) => {
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions: newValue as Array<SearchFilterOption>,
			}),
		);

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: search_id,
				newOptions: furnishing.active_options.map((item: SearchFilterOptionTextActive) => ({
					...item,
					is_selected: newValue.some((obj) => obj.id === item.id) ? true : false,
				})),
			}),
		);
	};

	React.useEffect(() => {
		// lets check if the furnishing is selected or not
		let isFurnishingSelected = furnishing ? (furnishing.selected_options.length !== 0 ? true : false) : false;

		// lets set the isApplied value to true if the furnishing is selected
		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: search_id, isApplied: isFurnishingSelected }));
	}, [furnishing, search_id, dispatch]);

	return (
		<CustomAccordion
			disableGutters={true}
			square={true}
			onChange={() => {
				setSlide(!slide);
			}}
		>
			<CustomAccordionSummary
				expandIcon={<CustomArrowIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header"
			>
				<CustomChairOutlinedIcon /> Furnishing {furnishing.selected_options.length !== 0 && <CustomSelectedIcon />}
			</CustomAccordionSummary>
			<Divider
				sx={{
					borderBottom: "1px solid var(--Other-New-Divider, rgba(255, 255, 255, 0.12))",
				}}
			/>
			<Slide
				direction="up"
				in={slide}
				mountOnEnter
				unmountOnExit
			>
				<CustomAccordionDetails>
					{furnishing.possible_options.map((item: SearchFilterOption) => (
						<CheckBoxDiv
							key={item.id}
							onClick={() => {
								furnishing.selected_options.some((obj: SearchFilterOption) => obj.id === item.id)
									? handleRemoveData(item)
									: handleUpdateFurnishing([...furnishing.selected_options, item]);
							}}
							sx={{
								background: furnishing.selected_options.some((obj: SearchFilterOption) => obj.id === item.id)
									? "#FC801933"
									: "",
								border: furnishing.selected_options.some((obj: SearchFilterOption) => obj.id === item.id)
									? "1px solid var(--Other-New-Outline-Border, rgba(255, 255, 255, 0.23))"
									: "1px solid #C0C0C0",
							}}
						>
							{furnishing.selected_options.some((obj: SearchFilterOption) => obj.id === item.id) ? (
								<DoneIcon />
							) : (
								<AddIcon />
							)}
							<Text variant="body1">{item.title}</Text>
						</CheckBoxDiv>
					))}
				</CustomAccordionDetails>
			</Slide>
		</CustomAccordion>
	);
};
