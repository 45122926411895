/* Common imports */
import React from "react";

/* mui imports */
import {
	useTheme,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Divider,
	Typography,
	Slide,
	Button,
} from "@mui/material";
import { styled } from "@mui/system";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Brightness1Icon from "@mui/icons-material/Brightness1";

/* redux imports */
import {
	SearchFilter,
	SearchFilterId,
	SearchFilterOption,
	SearchFilterOptionText,
	SearchFilterOptionTextActive,
} from "../../redux-magic/sub-interfaces/search-types";
import StateInterface from "../../redux-magic/state-interface";
import {
	updateSearchFilterActiveOptionsThunk,
	updateSearchFilterAppliedThunk,
	updateSearchFilterSelectedOptionsThunk,
	updateSearchFilterVisibilityThunk,
	updateSearchFilterIsAccordionOpenThunk,
} from "../../redux-magic/thunks";
import { rest } from "lodash";

/* stylings */
const CustomAccordion = styled(Accordion)(({ theme }) => ({
	borderRadius: "16px",
	boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
	"&:: before": {
		display: "none",
	},
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
	padding: "var(--Spacing-spacing-sm, 8px) var(--Spacing-spacing-md, 16px)",
	margin: "0rem",
}));

const CustomArrowIcon = styled(KeyboardArrowDownOutlinedIcon)(({ theme }) => ({
	width: "1.5rem",
	height: "1.5rem",
	borderRadius: "4px",
	color: theme.palette.mode == "dark" ? "#FFFFFF" : "#623816",
	backgroundColor: "#FC801929",
}));

const CustomHomeWorkOutlinedIcon = styled(HomeWorkOutlinedIcon)(({ theme }) => ({
	marginRight: "1rem",
	color: theme.palette.primary.main,
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	flexWrap: "wrap",
	padding: "1rem",
	margin: "0rem",
	gap: "var(--Spacing-spacing-md, 16px)",
}));

const CheckBoxDiv = styled(Button)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "0.5rem",
	padding: "var(--Spacing-spacing-xs, 4px)",
	// boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
	gap: "var(--Spacing-spacing-xs, 4px)",
	cursor: "pointer",
}));

const Text = styled(Typography)(({ theme }) => ({
	fontSize: "1rem",
	fontWeight: "400",
	lineHeight: "1.125rem",
	padding: "3px 6px",
	textTransform: "capitalize",
	color: theme.palette.mode == "dark" ? "#FFFFFF" : "#623816",
}));

const DoneIcon = styled(DoneOutlinedIcon)(({ theme }) => ({
	color: theme.palette.mode == "dark" ? "#FFFFFF" : "#623816",
	height: "1rem",
	width: "1rem",
}));

const AddIcon = styled(AddOutlinedIcon)(({ theme }) => ({
	color: theme.palette.mode == "dark" ? "#FFFFFF" : "#623816",
	height: "1rem",
	width: "1rem",
}));

type VisibilityConfig = {
	[key: string]:
		| {
				[key: string]: string[];
		  }
		| string[];
};

export const _e_property_type = ({
	search_id,
	property_category_value,
	property_type,
	dispatch,
}: {
	search_id: SearchFilterId;
	property_category_value: string;
	property_type: StateInterface["search_filters_state"]["property_type"];
	dispatch: Function;
}) => {
	const [slide, setSlide] = React.useState<boolean>(property_type?.is_accordion_open);

	const handleUpdatePropertyType = (value: Array<SearchFilterOptionTextActive>) => {
		let selected_options: Array<SearchFilterOption> = value.map((option) => {
			let { is_selected, ...rest } = option;
			return rest;
		});

		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions: selected_options,
			}),
		);

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: search_id,
				newOptions: property_type.active_options.map((item) => ({
					...item,
					is_selected: item.id === value[0]?.id ? true : false,
				})),
			}),
		);
	};

	React.useEffect(() => {
		const isPropertyTypeSelected = property_type ? (property_type.selected_options.length !== 0 ? true : false) : false;

		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: search_id, isApplied: isPropertyTypeSelected }));

		const updateFilterVisibilityAndAccordion = (item: SearchFilterId, isVisible: boolean, isAccordionOpen: boolean) => {
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: item, isVisible }));
			dispatch(updateSearchFilterIsAccordionOpenThunk({ searchFilterId: item, isAccordionOpen }));
		};

		if (isPropertyTypeSelected) {
			property_type?.affects.forEach((item: SearchFilterId) => {
				const selectedPropertyType = property_type
					? (property_type.selected_options[0] as SearchFilterOptionText)
						? (property_type.selected_options[0] as SearchFilterOptionText).id
						: ""
					: "";

				const visibilityConfig: VisibilityConfig = {
					residential: {
						plot: ["area", "property_status", "amenities", "unit_facing", "plot_dimensions"],
						land_residential: ["area", "property_status", "land_facing"],
						apartment: [
							"bedrooms",
							"bathrooms",
							"balconies",
							"floors",
							"area",
							"property_status",
							"furnishing",
							"amenities",
						],
						penthouse: [
							"bedrooms",
							"bathrooms",
							"balconies",
							"floors",
							"area",
							"property_status",
							"furnishing",
							"amenities",
							"unit_facing",
						],
						villa: [
							"bedrooms",
							"bathrooms",
							"balconies",
							"floors",
							"area",
							"property_status",
							"furnishing",
							"amenities",
							"unit_facing",
						],
						builder_floor_apartment: [
							"bedrooms",
							"bathrooms",
							"balconies",
							"floors",
							"area",
							"property_status",
							"furnishing",
							"amenities",
							"unit_facing",
						],
						co_living: [
							"bedrooms",
							"bathrooms",
							"balconies",
							"floors",
							"area",
							"property_status",
							"furnishing",
							"amenities",
							"unit_facing",
						],
						row_house: [
							"bedrooms",
							"bathrooms",
							"balconies",
							"floors",
							"area",
							"property_status",
							"furnishing",
							"amenities",
							"unit_facing",
						],
						independent_house: [
							"bedrooms",
							"bathrooms",
							"balconies",
							"area",
							"property_status",
							"furnishing",
							"amenities",
							"unit_facing",
						],
					},
					warehousing: ["area", "property_status"],
					agricultural: {
						land_agricultural: ["area"],
						farm_plot: ["area", "plot_dimensions", "property_status", "unit_facing", "amenities"],
						farm_house: [
							"bedrooms",
							"bathrooms",
							"balconies",
							"area",
							"property_status",
							"furnishing",
							"amenities",
							"unit_facing",
						],
					},
					industrial: {
						factory: ["area", "property_status"],
						sez: ["area", "property_status"],
						land_industrial: ["area", "land_facing"],
						mining: ["area"],
					},
					commercial: {
						co_working: ["area", "seats", "property_status", "amenities", "unit_facing"],
						land_commercial: ["area", "land_facing"],
						building: ["area", "property_status", "furnishing", "amenities", "unit_facing"],
						sez: ["area", "property_status", "furnishing", "amenities", "unit_facing"],
						educational: ["area", "property_status", "furnishing", "amenities", "unit_facing"],
						shop: ["area", "property_status", "furnishing", "amenities", "unit_facing"],
						retail: ["area", "property_status", "furnishing", "amenities", "unit_facing"],
						hospitality: ["area", "property_status", "furnishing", "amenities", "unit_facing"],
						healthcare: ["area", "property_status", "furnishing", "amenities", "unit_facing"],
						office_space: ["area", "property_status", "furnishing", "amenities", "unit_facing"],
					},
				};

				const applicableFilters =
					(visibilityConfig[property_category_value] as { [key: string]: string[] })?.[selectedPropertyType] || [];

				const isVisible = applicableFilters.includes(item);
				// console.log("isVisible", item, isVisible, isVisible);

				updateFilterVisibilityAndAccordion(item, isVisible, isVisible);
			});
		} else {
			// console.log("isVisible else", property_type.affects);

			property_type.affects.forEach((item) => {
				updateFilterVisibilityAndAccordion(item, false, false);
			});
		}
	}, [property_type, search_id, dispatch, property_category_value]);

	return (
		<CustomAccordion
			square={true}
			disableGutters={true}
			defaultExpanded={property_type?.is_accordion_open as boolean}
			onChange={() => {
				setSlide(!slide);
			}}
		>
			<CustomAccordionSummary
				expandIcon={<CustomArrowIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header"
			>
				<CustomHomeWorkOutlinedIcon /> Property Type{" "}
				{property_type?.selected_options.length != 0 && (
					<Brightness1Icon sx={{ color: "primary.main", width: "8px", marginTop: "-0.25rem", marginLeft: "0.25rem" }} />
				)}
			</CustomAccordionSummary>
			<Divider
				sx={{
					borderBottom: "1px solid var(--Other-New-Divider, rgba(255, 255, 255, 0.12))",
				}}
			/>
			<Slide
				direction="up"
				in={slide}
				mountOnEnter
				unmountOnExit
			>
				<CustomAccordionDetails>
					{property_type?.active_options.map((item: SearchFilterOptionTextActive) => (
						<CheckBoxDiv
							key={item.id}
							onClick={() => {
								item.is_selected
									? handleUpdatePropertyType([] as Array<SearchFilterOptionTextActive>)
									: handleUpdatePropertyType([item] as Array<SearchFilterOptionTextActive>);
							}}
							sx={{
								background: item.is_selected ? "#FC801933" : "",
								border: item.is_selected
									? "1px solid var(--Other-New-Outline-Border, rgba(255, 255, 255, 0.23))"
									: "1px solid #C0C0C0",
							}}
						>
							{item.is_selected ? <DoneIcon /> : <AddIcon />}
							<Text variant="body1">{item.title === "Co-living" ? "Co-living/PG" : item.title}</Text>
						</CheckBoxDiv>
					))}
				</CustomAccordionDetails>
			</Slide>
		</CustomAccordion>
	);
};
